import { t } from "@lingui/macro";
import {
  CapacityAllocationRepetitionTypeEnum,
  CapacityAllocationTypeEnum,
  CreateCapacityAllocationDocument,
  CreateCapacityAllocationMutation,
  CreateCapacityAllocationMutationVariables,
  GetAllocationModalOptionsDocument,
  GetAllocationModalOptionsQuery,
  GetAllocationModalOptionsQueryVariables,
  ModalCapacityAllocationProjectOptionsDocument,
  ModalCapacityAllocationProjectOptionsQuery,
  ModalCapacityAllocationProjectOptionsQueryVariables,
  ModalCapacityAllocationTaskOptionsDocument,
  ModalCapacityAllocationTaskOptionsQuery,
  ModalCapacityAllocationTaskOptionsQueryVariables,
  Project,
  SimpleTaskFragment,
  Task,
  UpdateCapacityAllocationDocument,
  UpdateCapacityAllocationForDayDocument,
  UpdateCapacityAllocationForDayMutation,
  UpdateCapacityAllocationForDayMutationVariables,
  UpdateCapacityAllocationItemDocument,
  UpdateCapacityAllocationItemMutation,
  UpdateCapacityAllocationItemMutationVariables,
  UpdateCapacityAllocationMutation,
  UpdateCapacityAllocationMutationVariables,
  User,
} from "@src/__generated__/urql-graphql";
import { AllocationModal } from "@src/components/modules/resource-planning/timeline/AllocationModal";
import { TProgressBarSegment } from "@src/components/ui-kit/NewProgressBar";
import { resetTime } from "@src/components/ui-kit/TaskItem/TaskItemComponents";
import { client } from "@src/services/client";
import { AppStore } from "@src/stores/AppStore";
import { BaseStore } from "@src/stores/BaseStore";
import { ModalStore } from "@src/stores/ModalStore";
import {
  AllocationFormData,
  AllocationFormState,
} from "@src/stores/forms/AllocationFormState";
import { AffectAllocationOccurrences } from "@src/types/planning";
import { isAfterOrSame } from "@src/utils/dates";
import { HUNDRED_PERCENT } from "@src/utils/formatters";
import mapToOptions from "@src/utils/map-to-options";
import { UserOption } from "@src/utils/map-to-options/users";
import { WorkTypeOption } from "@src/utils/map-to-options/workTypes";
import { BooleanState } from "@src/utils/mobx/states/BooleanState";
import { DisclosureState } from "@src/utils/mobx/states/DisclosureState";
import { Prettify } from "@src/utils/types";
import { isBefore, isSameDay } from "date-fns";
import { cloneDeep, compact } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";
import { now } from "mobx-utils";
import { MarkOptional } from "ts-essentials";

export type InitialCapacityAllocationModalState = {
  userId: User["id"];
  date: Date;
  task?: SimpleTaskFragment;
};

type AllocationModalOptions = Prettify<
  | {
      formData: AllocationFormData;
      affectOccurrences: AffectAllocationOccurrences;
      userId?: undefined;
      date?: undefined;
      onSubmit?: () => void;
    }
  | {
      formData: MarkOptional<AllocationFormData, "id">;
      affectOccurrences: AffectAllocationOccurrences;
      userId?: undefined;
      date?: undefined;
      onSubmit?: () => void;
    }
  | ({
      formData?: undefined;
      affectOccurrences?: undefined;
      onSubmit?: () => void;
    } & InitialCapacityAllocationModalState)
>;

export class AllocationModalStore implements BaseStore, ModalStore {
  appStore: AppStore;
  readonly modalId = "allocationModal";

  modalState = new DisclosureState<AllocationModalOptions>({
    onClose: () => {
      this.form.reset({ preserveAutoFilledState: false });
      this.resetSelectedTask();
      this.initFormData = undefined;
      this.appStore.UIStore.dialogs.closeModal(this.modalId);
    },
    onOpen: (initialState) => {
      this.initFormData = cloneDeep(initialState?.formData);
      this.appStore.UIStore.dialogs.openModal({
        id: this.modalId,
        content: <AllocationModal />,
      });
      this.fetchOptions();
      if (
        !this.appStore.workspaceStore.settings
          ?.specific_time_in_planning_enabled
      ) {
        this.form.from_time.onChange(
          this.appStore.workspaceStore.defaultAllocationTime[
            CapacityAllocationTypeEnum.Task
          ].from_time,
        );
        this.form.to_time.onChange(
          this.appStore.workspaceStore.defaultAllocationTime[
            CapacityAllocationTypeEnum.Task
          ].to_time,
        );
      }

      this.form.affect_occurrences.onChange(initialState?.affectOccurrences);
      this.form.specific_time.onChange(
        !!this.appStore.workspaceStore.settings
          ?.specific_time_in_planning_enabled,
      );
      if (initialState?.formData && initialState?.affectOccurrences) {
        this.form.convertAllocationItemToFromState(
          initialState.formData,
          initialState.affectOccurrences,
        );
      }
      if (initialState?.userId && initialState?.date) {
        this.form.autoFillOnOpen(initialState);
      }
    },
  });

  isSubmitting = new BooleanState(false);

  @observable isLoading = false;

  @observable.ref form = new AllocationFormState(this);

  @observable workTypeOptions: WorkTypeOption[] = [];
  @observable users: NonNullable<
    GetAllocationModalOptionsQuery["userSimpleMap"]
  > = [];
  @observable selectedTask:
    | ModalCapacityAllocationTaskOptionsQuery["task"]
    | undefined = undefined;
  @observable initFormData: AllocationModalOptions["formData"] = undefined;

  constructor(appStore: AppStore) {
    makeObservable(this);
    this.appStore = appStore;
  }

  @computed get isEditMode(): boolean {
    return !!this.modalState.additionalData?.formData?.id;
  }

  @computed get canEditTask(): boolean {
    return (
      !this.modalState.additionalData?.formData?.id ||
      !this.modalState.additionalData.formData.tracked_for_item ||
      this.modalState.additionalData?.formData?.tracked_for_item === 0
    );
  }

  @computed get plannableUsers(): NonNullable<
    GetAllocationModalOptionsQuery["userSimpleMap"]
  > {
    return this.users.filter((user) => user.profile.plannable);
  }
  @computed get userOptions(): UserOption[] {
    return mapToOptions.users(this.users);
  }

  @computed get plannableUserOptions(): UserOption[] {
    return mapToOptions.users(this.plannableUsers);
  }

  @computed get shouldAssignUserPositions(): boolean {
    return (
      this.form.create_task_positions.value ||
      this.form.assignment_type.value === "users"
    );
  }

  @computed get editingCurrentItem() {
    return (
      this.isEditMode &&
      (this.form.affect_occurrences.$ === "currentItem" ||
        this.form.affect_occurrences.$ === "thisDay")
    );
  }

  @computed get editingAllOccurrences() {
    if (!this.modalState?.additionalData?.formData?.item?.userItems?.length)
      return false;
    return (
      this.form.affect_occurrences.$ === "allOccurrences" &&
      this.modalState.additionalData.formData.item.userItems.length > 1
    );
  }

  @computed get today(): Date {
    return resetTime(new Date(now()));
  }

  @computed get isCurrentItemInPast(): boolean {
    return (
      this.form.repetition.value ===
        CapacityAllocationRepetitionTypeEnum.Once &&
      isSameDay(this.form.from_date.value, this.form.to_date.value) &&
      isBefore(resetTime(this.form.from_date.value), this.today)
    );
  }

  @computed get thisAllocationTotalTime(): number {
    const inputTime = this.form.specific_time.value
      ? this.form.totalTimeFromSpecificTime
      : this.form.total_time.value;
    const timePerDay = this.form.specific_time.value
      ? this.form.timePerDayFromSpecificTime
      : this.form.total_time_per_day.value;
    const startDate = isBefore(resetTime(this.form.from_date.value), this.today)
      ? this.today
      : this.form.from_date.value;
    const usableAllocationOccurrenceCount =
      this.form.calculateOccurrenceForType(
        startDate,
        this.form.to_date.value,
        this.form.repetition.value,
        this.form.repetition_every.value,
        this.form.repetition_days.value ?? [],
        this.form.include_holidays.value,
      );

    if (!this.initFormData?.item || !this.isEditMode) {
      if (this.isCurrentItemInPast) return 0;

      return (
        timePerDay * usableAllocationOccurrenceCount * this.form.assigneeCount
      );
    }

    const currentItem = this.initFormData.item;

    switch (this.form.affect_occurrences.value) {
      case "currentItem":
        return currentItem.completed || this.isCurrentItemInPast
          ? 0
          : inputTime;
      case "thisDay":
        const isInPast = isBefore(resetTime(currentItem.date), this.today);

        return isInPast
          ? 0
          : currentItem.capacityAllocation.capacityAllocationItems
              .filter(({ date }) => {
                return isSameDay(date, currentItem.date);
              })
              .reduce((prev, curr) => {
                if (curr.completed) return prev;
                return prev + timePerDay;
              }, 0);
      default:
        return (
          timePerDay *
          (usableAllocationOccurrenceCount * this.form.assigneeCount -
            // Count of future completed allocations, we don't account those in our calculations
            currentItem.capacityAllocation.capacityAllocationItems.reduce(
              (prev, curr) => {
                if (isBefore(resetTime(curr.date), this.today)) return prev;
                if (!curr.completed) return prev;

                // In case someone removed user from form that had completed allocations, so they aren't
                // account for
                const isUserInForm = Array.from(this.form.people.$.values())
                  .map(({ $ }) => $.user_id.value)
                  .includes(curr.user.id);
                return isUserInForm ? prev + 1 : prev;
              },
              0,
            ))
        );
    }
  }

  @computed get otherAllocationsTotalTime(): number {
    if (!this.selectedTask?.stats) return 0;
    const taskAllocatedTime = this.selectedTask.stats.allocated_time;

    if (!this.initFormData?.item || !this.isEditMode) return taskAllocatedTime;
    const currentItem = this.initFormData.item;

    switch (this.form.affect_occurrences.value) {
      case "currentItem":
        return currentItem.completed ||
          isBefore(resetTime(currentItem.date), this.today)
          ? taskAllocatedTime
          : taskAllocatedTime - currentItem.total_time;
      case "thisDay":
        const openedDate = currentItem.date;
        return (
          taskAllocatedTime -
          currentItem.capacityAllocation.capacityAllocationItems
            .filter(({ date }) => {
              return (
                isAfterOrSame(resetTime(date), this.today) &&
                isSameDay(date, openedDate)
              );
            })
            .reduce(this.addTotalTime, 0)
        );
      default:
        return (
          taskAllocatedTime -
          currentItem.capacityAllocation.capacityAllocationItems
            .filter(({ date }) => {
              return isAfterOrSame(resetTime(date), this.today);
            })
            .reduce(this.addTotalTime, 0)
        );
    }
  }

  addTotalTime(
    prev: number,
    curr: AllocationFormData["item"]["capacityAllocation"]["capacityAllocationItems"][0],
  ): number {
    if (curr.completed) return prev;
    return prev + curr.total_time;
  }

  @computed get trackedPlusAllocated(): number {
    if (!this.selectedTask?.stats) return 0;
    return (
      this.selectedTask.stats.history_spent_time +
      this.otherAllocationsTotalTime +
      this.thisAllocationTotalTime
    );
  }

  @computed get leftToAllocate(): number {
    if (!this.selectedTask?.stats) return 0;
    return this.selectedTask.stats.planned_time - this.trackedPlusAllocated;
  }

  @computed get taskAllocationSegments(): TProgressBarSegment[] {
    if (!this.selectedTask?.stats) return [];
    const segments: TProgressBarSegment[] = [];
    const { planned_time, history_spent_time } = this.selectedTask.stats;

    segments.push({
      name: "tracked",
      percent: (history_spent_time / planned_time) * HUNDRED_PERCENT,
      bg: "teal.400",
    });

    segments.push({
      name: "other-allocations",
      percent:
        (this.otherAllocationsTotalTime / planned_time) * HUNDRED_PERCENT,
      bg: "#956FD7",
    });

    segments.push({
      name: "this-allocation",
      percent: (this.thisAllocationTotalTime / planned_time) * HUNDRED_PERCENT,
      bg: "#c4a1ff",
    });

    segments.push({
      name: "left-to-allocate",
      percent: (Math.abs(this.leftToAllocate) / planned_time) * HUNDRED_PERCENT,
      bg: this.leftToAllocate >= 0 ? "gray.200" : "red.500",
    });

    return segments;
  }

  @action resetSelectedTask() {
    this.selectedTask = undefined;
  }

  async fetchOptions() {
    this.isLoading = true;
    const { data } = await client
      .query<
        GetAllocationModalOptionsQuery,
        GetAllocationModalOptionsQueryVariables
      >(GetAllocationModalOptionsDocument, {})
      .toPromise();

    this.isLoading = false;
    if (!data) return;

    this.users = data.userSimpleMap;
  }

  async fetchPersonOptions({
    projectId,
    taskId,
    autoCompleteUserPositionFromTask,
  }: {
    projectId?: Project["id"];
    taskId: Task["id"];
    autoCompleteUserPositionFromTask: boolean;
  }) {
    const { data: taskData, error: taskError } = await client
      .query<
        ModalCapacityAllocationTaskOptionsQuery,
        ModalCapacityAllocationTaskOptionsQueryVariables
      >(ModalCapacityAllocationTaskOptionsDocument, {
        taskId,
      })
      .toPromise();

    if (!taskError && taskData?.task) {
      this.selectedTask = taskData.task;
      if (!projectId) {
        this.workTypeOptions = mapToOptions.workTypes(
          compact(
            taskData.task.positions.flatMap(
              (position) => position.timeTrackingWorkType,
            ),
          ),
        );
      }

      autoCompleteUserPositionFromTask &&
        this.form.autoCompleteUserPositionFromTask(taskData.task);
    }

    if (!projectId) return;

    const { data: projectData, error: projectError } = await client
      .query<
        ModalCapacityAllocationProjectOptionsQuery,
        ModalCapacityAllocationProjectOptionsQueryVariables
      >(ModalCapacityAllocationProjectOptionsDocument, {
        projectId,
      })
      .toPromise();

    if (projectError || !projectData?.projectForTask) return;
    this.workTypeOptions = mapToOptions.workTypes(
      projectData.projectForTask.availableTimeTrackingWorkTypes,
    );
  }

  onSubmit = async (updateFromCurrentItem?: boolean) => {
    const { hasError } = await this.form.validate();
    if (hasError) return;
    this.isSubmitting.on();
    this.isEditMode
      ? await this.update(updateFromCurrentItem)
      : await this.create();
    this.isSubmitting.off();
  };

  private create = () => {
    return client
      .mutation<
        CreateCapacityAllocationMutation,
        CreateCapacityAllocationMutationVariables
      >(CreateCapacityAllocationDocument, {
        input: this.form.serialize({
          action: "create",
          type: "capacityAllocation",
        }),
      })
      .toPromise()
      .then((value) => {
        if (value.data?.createCapacityAllocation)
          this.onSubmitSuccess("created");
      });
  };

  private updateCurrentItem = () => {
    return client
      .mutation<
        UpdateCapacityAllocationItemMutation,
        UpdateCapacityAllocationItemMutationVariables
      >(UpdateCapacityAllocationItemDocument, {
        input: this.form.serialize({
          action: "update",
          type: "capacityAllocation",
          affect: "currentItem",
        }),
      })
      .toPromise()
      .then((value) => {
        if (value.data?.updateCapacityAllocationItem)
          this.onSubmitSuccess("updated");
      });
  };

  private updateThisDay = () => {
    return client
      .mutation<
        UpdateCapacityAllocationForDayMutation,
        UpdateCapacityAllocationForDayMutationVariables
      >(UpdateCapacityAllocationForDayDocument, {
        input: this.form.serialize({
          action: "update",
          type: "capacityAllocation",
          affect: "thisDay",
        }),
      })
      .toPromise()
      .then((value) => {
        if (value.data?.UpdateCapacityAllocationForDay)
          this.onSubmitSuccess("updated");
      });
  };

  private updateAllOccurrences = (updateFromCurrentItem?: boolean) => {
    return client
      .mutation<
        UpdateCapacityAllocationMutation,
        UpdateCapacityAllocationMutationVariables
      >(UpdateCapacityAllocationDocument, {
        input: this.form.serialize({
          action: "update",
          type: "capacityAllocation",
          affect: updateFromCurrentItem
            ? "allOccurrencesFromToday"
            : "allOccurrences",
        }),
      })
      .toPromise()
      .then((value) => {
        if (value.data?.updateCapacityAllocation)
          this.onSubmitSuccess("updated");
      });
  };

  private update = (updateFromCurrentItem?: boolean) => {
    if (this.form.affect_occurrences.value === "currentItem")
      return this.updateCurrentItem();
    if (this.form.affect_occurrences.value === "thisDay")
      return this.updateThisDay();
    if (this.form.affect_occurrences.value === "allOccurrences")
      return this.updateAllOccurrences(updateFromCurrentItem);
    return null;
  };

  private onSubmitSuccess = (action: "created" | "updated"): void => {
    this.modalState.additionalData?.onSubmit?.();
    this.modalState.close();
    this.appStore.UIStore.toast({
      title:
        action === "created"
          ? t`Allocation successfully created`
          : t`Allocation successfully updated`,
      status: "success",
    });
    this.form.reset({ preserveAutoFilledState: false });
    this.resetSelectedTask();
  };
}
